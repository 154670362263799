<template>
  <v-app>
    <template v-if="userVerified">
      <v-container class="public-doc-view" fluid>
        <v-layout class="public-doc-topbar">
          <v-flex md4 class="my-auto">
            <div class="d-flex">
              <div class="brand-logo">
                <img :src="siteLogo()" alt="Logo" />
              </div>
              <div class="my-auto ml-4">
                <h2 class="font-size-18 font-weight-600 m-0">
                  {{ getTitle() }}
                </h2>
                <p class="m-0 text--secondary" v-if="detail.added_by">
                  by {{ detail.added_by.full_name }}
                </p>
              </div>
            </div>
          </v-flex>
          <v-flex class="my-auto text-right" md8>
            <v-btn
              class="custom-bold-button white--text"
              color="cyan"
              :loading="dataLoading"
              :disabled="!formValid || dataLoading"
              v-on:click="saveDocument('draft')"
              >Save as Draft</v-btn
            >
            <v-btn
              class="custom-bold-button mx-4 white--text"
              color="cyan"
              :loading="dataLoading"
              :disabled="!formValid || dataLoading"
              v-on:click="submitDocument()"
              >Submit</v-btn
            >
            <v-btn
              class="custom-bold-button white--text"
              color="red lighten-1"
              v-on:click="closeWindow()"
              >Close</v-btn
            >
          </v-flex>
        </v-layout>
        <v-form
          ref="documentForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="saveDocument('draft')"
        >
          <v-row>
            <v-col md="8" offset-md="2">
              <div class="public-doc-pages">
                <div
                  v-for="(row, index) in detail.pages"
                  :key="index"
                  :class="`my-6 public-doc-page public-doc-page-${row.page}`"
                >
                  <v-img
                    contain
                    :src="row.image"
                    :lazy-src="$defaultImage"
                  ></v-img>
                  <div
                    :class="`public-doc-page-elements public-doc-page-elements-${row.page}`"
                  >
                    <div
                      v-for="(element, elindex) in getPageElements(row.page)"
                      :key="elindex"
                      :id="`element-unique-${element.uuid}`"
                      class="my-auto"
                      v-bind:style="{
                        position: 'absolute',
                        left: element.left + 'px',
                        top: element.top + 52 + 'px',
                        width: getWidth(element.type),
                      }"
                    >
                      <span
                        :class="`element-content-left error-block-${element.type}`"
                        >This field is required.</span
                      >
                      <template v-if="element.type == 'text'">
                        <v-text-field
                          v-model="element.value"
                          dense
                          filled
                          v-on:change="validateFieldValue()"
                          :placeholder="getPlaceholder(element, 'Text')"
                          solo
                          class="public-doc-elements"
                          flat
                          :rules="getTextRules(element)"
                          hide-details
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <template v-if="element.type == 'date'">
                        <Datepicker
                          v-model="element.value"
                          custom-class="public-doc-elements"
                          solo
                          flat
                          v-on:change="validateFieldValue()"
                          :mandatory="isDateMandatory(element)"
                          hide-details
                          nullable
                          :placeholder="getPlaceholder(element, 'Date')"
                        ></Datepicker>
                      </template>
                      <template v-if="element.type == 'formatted-date'">
                        <DateFormatted
                          v-model="element.value"
                          :mandatory="isDateMandatory(element)"
                        ></DateFormatted>
                      </template>
                      <template
                        v-if="
                          element.type == 'checkbox' ||
                          element.type == 'crossbox'
                        "
                      >
                        <v-checkbox
                          :on-icon="getIcon(element.type)"
                          color="green darken-4"
                          hide-details
                          true-value="1"
                          false-value="0"
                          class="my-0 py-0"
                          v-on:change="validateFieldValue()"
                          v-model="element.value"
                          :class="`public-doc-element-${element.type}`"
                        ></v-checkbox>
                      </template>
                      <template v-if="element.type == 'signature'">
                        <div
                          class="public-doc-elements public-doc-page-elements-signature"
                          v-on:click="
                            signatureDialog(element.type, element.uuid)
                          "
                        >
                          <v-img
                            contain
                            v-if="element.value"
                            :src="element.value"
                          ></v-img>
                          <div
                            v-else
                            class="public-doc-page-elements-signature-placeholder"
                          >
                            <span class="text-capitalize font-size-16">{{
                              element.type
                            }}</span>
                          </div>
                        </div>
                      </template>
                      <template v-if="element.type == 'initial'">
                        <div
                          class="public-doc-elements public-doc-page-elements-initial"
                          v-on:click="
                            signatureDialog(element.type, element.uuid)
                          "
                        >
                          <v-img
                            contain
                            v-if="element.value"
                            :src="element.value"
                          ></v-img>
                          <div
                            v-else
                            class="public-doc-page-elements-signature-placeholder"
                          >
                            <span class="text-capitalize font-size-16">{{
                              element.type
                            }}</span>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <Dialog :commonDialog.sync="signDialog">
        <template v-slot:title> Signature </template>
        <template v-slot:body>
          <v-row>
            <v-col md="10" class="py-0" offset-md="1">
              <div
                id="signaturec-pad"
                ref="signaturec_div"
                class="custom-border-grey-dashed"
                :class="{ 'bg-grey': signaturecStatus }"
              >
                <canvas ref="signaturec"></canvas>
              </div>
            </v-col>
            <v-col v-if="signaturecStatus" md="10" class="py-0" offset-md="1">
              <v-progress-linear
                color="cyan"
                indeterminate
                height="6"
              ></v-progress-linear>
            </v-col>
            <v-col md="10" v-if="!signaturecStatus" class="py-0" offset-md="1">
              <p class="float-right m-0">
                <v-btn
                  content="Click here to clear signaturec"
                  v-tippy="{ arrow: true, placement: 'top' }"
                  icon
                  small
                  class="float-right"
                  v-on:click="signaturecRef.clear()"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </p>
            </v-col>
          </v-row>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="submitSignature()"
          >
            Submit
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="closeDialog()"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
    </template>
    <v-snackbar top v-model="snackbar" :color="getSnackbarColor()">
      <h3
        class="m-0 font-size-16 font-weight-500"
        v-if="message"
        v-html="message"
      ></h3>
      <h3
        class="m-0 font-size-16 font-weight-500"
        v-if="error"
        v-html="error"
      ></h3>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          icon
          v-bind="attrs"
          v-on:click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <Dialog :commonDialog.sync="codeDialog">
      <template v-slot:title> Verification </template>
      <template v-slot:body>
        <v-form
          ref="verificationForm"
          v-model.trim="formCValid"
          lazy-validation
          v-on:submit.stop.prevent="verifyUser()"
        >
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model.trim="securityCode"
                dense
                filled
                label="Security Code"
                solo
                :disabled="codeLoading"
                :loading="codeLoading"
                flat
                color="cyan"
                :rules="[validateRules.required(securityCode, 'Security Code')]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formCValid || codeLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="verifyUser()"
        >
          Submit
        </v-btn>
      </template>
    </Dialog>
    <Dialog :commonDialog.sync="submitDialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-container class="p-0" fluid>
          <h2
            class="font-size-16 mb-4 font-weight-500 blue-grey--text text--darken-4"
          >
            Are you sure, you want to submit this document ?
          </h2>
          <em class="text--secondary font-weight-500"
            >Note: Once you submit, you won't be able to edit this later.</em
          >
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="submitDialog = false"
          :disabled="dataLoading"
        >
          No! Cancel
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          :loading="dataLoading"
          :disabled="dataLoading"
          v-on:click="saveDocument('submit')"
        >
          Yes! Submit
        </v-btn>
      </template>
    </Dialog>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import {
  PUT,
  VERIFY_GET_USER,
  VERIFY_PUT_USER,
  VERIFY_POST_USER,
} from "@/core/services/store/request.module";
import Datepicker from "@/view/pages/partials/Datepicker";
import Dialog from "@/view/pages/partials/Dialog";
import SignaturePad from "signature_pad";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DateFormatted from "@/view/pages/docs/Date-Formatted-Prop";

export default {
  mixins: [ValidationMixin],
  data() {
    return {
      docId: null,
      token: null,
      h_uuid: null,
      t_interval: null,
      dataLoading: true,
      codeDialog: true,
      codeLoading: false,
      userVerified: false,
      snackbar: false,
      detail: {},
      signature: null,
      securityCode: null,
      message: null,
      error: null,
      signDialog: false,
      signaturecRef: null,
      signaturecType: null,
      signaturecStatus: true,
      signTargetUUID: null,
      elements: [],
      formValid: true,
      formCValid: true,
      submitDialog: false,
    };
  },
  components: {
    Dialog,
    Datepicker,
    DateFormatted,
  },
  methods: {
    getTextRules(textElement) {
      if (textElement.required == 1) {
        const placeholder = this.getPlaceholder(textElement, "Text");
        return [this.validateRules.required(textElement.value, placeholder)];
      }
      return [];
    },
    isDateMandatory(dateElement) {
      if (dateElement.required == 1) {
        return true;
      }
      return false;
    },
    submitDocument() {
      this.submitDialog = true;
    },
    getIcon(type) {
      if (type == "checkbox") {
        return "mdi-check";
      } else if (type == "crossbox") {
        return "mdi-window-close";
      }
    },
    getWidth(type) {
      if (type == "formatted-date") {
        return "170px";
      }
      if (type == "checkbox" || type == "crossbox") {
        return "30px";
      }
      return "200px";
    },
    getSnackbarColor() {
      if (this.message) {
        return "success";
      }
      if (this.error) {
        return "error";
      }
    },
    verifyUser() {
      const _this = this;

      if (!_this.$refs.verificationForm.validate()) {
        return false;
      }

      _this.message = null;
      _this.error = null;
      _this.snackbar = false;

      _this.codeLoading = true;
      _this.$store
        .dispatch(VERIFY_POST_USER, {
          url: `docs/${_this.docId}/verify-user`,
          token: _this.token,
          data: { security_code: _this.securityCode },
        })
        .then(({ message, data }) => {
          _this.userVerified = true;
          _this.codeDialog = false;
          _this.message = message;
          _this.h_uuid = data.h_uuid;
          _this.snackbar = true;
          _this.getDocument();
          _this.updateHistory();
        })
        .catch((error) => {
          if (error && error.response) {
            const { data } = error.response;
            if (data && data.message) {
              _this.error = data.message;
              _this.snackbar = true;
            } else {
              _this.error = "Something went wrong. Please try again later";
              _this.snackbar = true;
            }
          } else {
            _this.error = "Something went wrong. Please try again later";
            _this.snackbar = true;
          }
          _this.logError(error);
        })
        .finally(() => {
          _this.codeLoading = false;
        });
    },
    getTitle() {
      if (this.detail.name) {
        const name = this.detail.name.split(".");
        name.splice(name.length - 1, 1);
        return name.join("");
      }
    },
    closeWindow() {
      window.close();
    },
    validateFieldValue() {
      const hideErrors = document.getElementsByClassName(
        "element-content-left"
      );
      for (let i = 0; i < hideErrors.length; i++) {
        hideErrors[i].style.display = "none";
      }
    },
    validateForm() {
      const _this = this;

      _this.validateFieldValue();

      return new Promise((resolve, reject) => {
        let status = [];

        for (let i = 0; i < _this.elements.length; i++) {
          if (_this.elements[i].required == 1 && !_this.elements[i].value) {
            const placeholder = _this.getPlaceholder(
              _this.elements[i],
              _this.elements[i].type
            );

            const elem = document.getElementById(
              `element-unique-${_this.elements[i].uuid}`
            );
            const elemChildNode = elem.querySelector(".element-content-left");
            elemChildNode.style.display = "block";

            status.push("Please fill the " + placeholder + " field");
          }
        }

        if (status.length) {
          const message = status.join("<br>");
          reject(message);
        } else {
          if (!_this.$refs.documentForm.validate()) {
            reject("Please fill the required fields");
          } else {
            resolve(true);
          }
        }
      });
    },
    saveDocument(action) {
      const _this = this;

      _this.snackbar = false;
      _this.$nextTick(() => {
        _this.message = null;
        _this.error = null;
      });

      _this
        .validateForm()
        .then(() => {
          _this.dataLoading = true;
          _this.$store
            .dispatch(VERIFY_PUT_USER, {
              url: `docs/${_this.docId}/public`,
              token: _this.token,
              data: {
                action,
                security_code: _this.securityCode,
                elements: _this.elements,
                h_uuid: _this.h_uuid,
              },
            })
            .then(({ message }) => {
              _this.message = message;
              _this.snackbar = true;
              if (action == "submit") {
                clearInterval(_this.t_interval);
              }
            })
            .catch((error) => {
              if (error && error.response) {
                const { data } = error.response;
                if (data && data.message) {
                  _this.error = data.message;
                  _this.snackbar = true;
                } else {
                  _this.error = "Something went wrong. Please try again later";
                  _this.snackbar = true;
                }
              } else {
                _this.error = "Something went wrong. Please try again later";
                _this.snackbar = true;
              }
              _this.logError(error);
            })
            .finally(() => {
              _this.dataLoading = false;
              _this.submitDialog = false;
            });
        })
        .catch((error) => {
          _this.error = error;
          _this.snackbar = true;
          _this.submitDialog = false;
        });
    },
    getPageElements(page) {
      if (this.elements) {
        return this.lodash.filter(this.elements, { page });
      }
      return [];
    },
    submitSignature() {
      if (this.signaturecRef.isEmpty()) {
        return false;
      }
      if (this.signTargetUUID) {
        const eleIndex = this.lodash.findIndex(this.elements, {
          uuid: this.signTargetUUID,
        });
        this.elements[eleIndex].value = this.signaturecRef.toDataURL();
        this.closeDialog();
      }
    },
    signatureDialog(type, targetUUID) {
      this.signDialog = true;
      this.signaturecType = type;
      this.signTargetUUID = targetUUID;
      this.initSign();
    },
    closeDialog() {
      this.signDialog = false;
      this.signaturecStatus = true;
      this.signaturecType = null;
      this.signTargetUUID = null;
      if (this.signaturecRef) {
        this.signaturecRef.clear();
      }
      this.signaturecRef = null;
    },
    initSign() {
      const _this = this;
      setTimeout(function () {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["signaturec"];
          let cparentDiv = document.getElementById("signaturec-pad");
          if (cparentDiv) {
            let cparentWidth = cparentDiv.offsetWidth;
            ccanvas.setAttribute("width", cparentWidth);
            _this.signaturecRef = new SignaturePad(ccanvas);
            _this.signaturecStatus = false;
          }
        });
      }, 500);
    },
    getPlaceholder(row, type) {
      if (row && row.placeholder) {
        return row.placeholder;
      }
      return type;
    },
    getDocument() {
      this.message = null;
      this.error = null;
      this.snackbar = false;

      this.$store
        .dispatch(VERIFY_GET_USER, {
          url: `docs/${this.docId}/public`,
          token: this.token,
          data: { security_code: this.securityCode, h_uuid: this.h_uuid },
        })
        .then(({ data }) => {
          this.detail = data;
          this.elements = data.elements;
        })
        .catch((error) => {
          if (error && error.response) {
            const { data } = error.response;
            if (data && data.message) {
              this.error = data.message;
              this.snackbar = true;
            } else {
              this.error = "Something went wrong. Please try again later";
              this.snackbar = true;
            }
          } else {
            this.error = "Something went wrong. Please try again later";
            this.snackbar = true;
          }
          this.logError(error);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.VUE_APP_BASE_URL + logo;
    },
    updateHistory() {
      const _this = this;
      _this.t_interval = setInterval(function () {
        _this.$store.dispatch(PUT, {
          url: `docs/${_this.docId}/public/history`,
          data: { h_uuid: _this.h_uuid },
        });
      }, 2000);
    },
  },
  beforeDestroy() {
    clearInterval(this.t_interval);
  },
  beforeMount() {
    this.docId = this.lodash.toSafeInteger(this.$route.params.id);
    this.token = this.lodash.toString(this.$route.query.token);
  },
  mounted() {
    // this.getDocument();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
